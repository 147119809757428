// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/res/images/ceramic-grey.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\r\n#root {\r\n  overflow-x: hidden;\r\n}\r\n\r\n#root>div>div>div:nth-child(1)>div:nth-child(3)>div>form>div.mt-3 {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.containerC {\r\n  background-color: white;\r\n  z-index: -1;\r\n  height: 100%;\r\n  position: absolute;\r\n}\r\n\r\n.headerLogoContainerC {\r\n  padding: 5% 0 0 5%;\r\n}\r\n\r\n.bubbleC {\r\n  height: 23%;\r\n  width: 71%;\r\n  position: absolute;\r\n  top: -14%;\r\n  right: -25%;\r\n  z-index: -2;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-position: center;\r\n  border-radius: 50%;\r\n}\r\n\r\n.bottomBubbleC {\r\n  height: 15%;\r\n  width: 103%;\r\n  left: -4px;\r\n  position: absolute;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-position: center;\r\n  top: 24%;\r\n  border-radius: 50%;\r\n  z-index: -2;\r\n}\r\n\r\n.bottomRectangleC {\r\n  height: 69%;\r\n  width: 100%;\r\n  position: absolute;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-position: center;\r\n  bottom: 0;\r\n  z-index: -2;\r\n}\r\n\r\n#contactform>div:nth-child(3)>div>div>a>u {\r\n  color: white;\r\n}\r\n\r\n#contactform>div:nth-child(4)>div>div>a>u {\r\n  color: white;\r\n}", ""]);
// Exports
module.exports = exports;
