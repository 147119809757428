// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/res/images/ceramic-grey.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container {\r\n  /* background-color: #fff; */\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  z-index: -2;\r\n  height: 100%;\r\n  position: absolute;\r\n}\r\n\r\n.headerLogoContainer {\r\n  padding: 5% 0 0 5%;\r\n}\r\n\r\n.bubble {\r\n  height: 29%;\r\n  width: 71%;\r\n  position: absolute;\r\n  top: -14%;\r\n  left: -25%;\r\n  z-index: -1;\r\n  background: white;\r\n  border-radius: 50%;\r\n}\r\n\r\n.bottomRectangle {\r\n  height: 50%;\r\n  width: 100%;\r\n  position: absolute;\r\n  background: white;\r\n  bottom: 0;\r\n  z-index: -1;\r\n}\r\n\r\n.bottomBubble {\r\n  height: 15%;\r\n  width: 103%;\r\n  left: -4px;\r\n  position: absolute;\r\n  background: white;\r\n  bottom: 43%;\r\n  border-radius: 50%;\r\n  z-index: -1;\r\n}\r\n\r\n.poweredByFooter {\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);\r\n  /* backdrop-filter: blur(8px);  */\r\n  -webkit-backdrop-filter: blur(8px);\r\n  border-radius: 20px;\r\n  height: 50px;\r\n  width: 90%;\r\n  /* margin-left: 10%;\r\n  margin-right: 10%; */\r\n  position: absolute;\r\n  bottom: 20px;\r\n  text-align: center;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  color: white;\r\n  flex-direction: row;\r\n}", ""]);
// Exports
module.exports = exports;
